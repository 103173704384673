<template>
    <div class="mx-1 px-4 py-2">

        <div class="flex flex-row">
            <div class="flex items-center pr-2">
                <v-tooltip :active="true" :text="'Mark as ' + (isRead ? 'unread' : 'read')">
                    <template #activator>
                        <v-icon-button :loading="changingStatus" class="bg-blue-100" type="button"
                                       @click="() => isRead ? markAsUnread() : markAsRead()">
                            <i :class="{'fa-envelope': !isRead, 'fa-envelope-open': isRead}" class="fa"></i>
                        </v-icon-button>
                    </template>
                </v-tooltip>
            </div>
            <div class="flex flex-col w-full">
                <h1 :class="{'font-semibold': isRead, 'font-bold': !isRead}" class="text-gray-700 dark:text-gray-200">
                    {{ props.notification.data.title }}</h1>
                <p class="text-sm text-gray-500 dark:text-gray-400 whitespace-pre"
                   v-text="props.notification.data.text"></p>
            </div>
        </div>
        <div class="flex flex-row space-x-1 space-y-1 flex-wrap ml-10 my-2">
            <div v-for="link in props.notification.data.links ?? []">
                <v-tooltip :active="!!link.helpText" :text="link.helpText" position="left">
                    <template #activator>
                        <v-button :href="link.href" target="_blank">
                            {{ link.text }}
                        </v-button>
                    </template>
                </v-tooltip>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import {Notification} from '../../Types/notifications';
import {computed, ref} from 'vue';
import axios from 'axios';
import { route } from 'ziggy-js';

const props = defineProps<{
    notification: Notification
}>();

const isRead = computed<boolean>(() => {
    return props.notification.read_at !== null;
});

const changingStatus = ref<boolean>(false);

const markAsRead = () => {
    changingStatus.value = true;
    axios.post(route('api.tools.notifications.notifications.mark-as-read', {notification: props.notification.id}))
        .then(() => {
            emit('changed');
        })
        .catch(error => {
            console.error(error);
            alert('Failed to mark notification as read');
        })
        .finally(() => {
            changingStatus.value = false;
        });
};

const markAsUnread = () => {
    changingStatus.value = true;
    axios.post(route('api.tools.notifications.notifications.mark-as-unread', {notification: props.notification.id}))
        .then(() => {
            emit('changed');
        })
        .catch(error => {
            console.error(error);
            alert('Failed to mark notification as unread');
        })
        .finally(() => {
            changingStatus.value = false;
        });
};

const emit = defineEmits<{
    (event: 'changed'): void
}>();

</script>


<style scoped>

</style>