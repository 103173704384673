<template>
    <v-icon-button @click="toggle" class="text-2xl ml-2">
        <i class="fa fa-bars text-black p-2" v-if="!sidebarShowing"></i>
        <i class="fa fa-times text-black p-2" v-else></i>
    </v-icon-button>
</template>

<script setup lang="ts">

import {sidebarShowing} from '../../Tools/sidebar/sidebar';

const toggle = () => {
    sidebarShowing.value = !sidebarShowing.value;
};

</script>

<style scoped>

</style>
