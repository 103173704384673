<template>
    <v-modal header="Notifications">
        <template #activator="{show}">
            <v-icon-button v-if="failedToFetchNotifications" type="button" :loading="fetchingNotifications">
                <v-icon fa-icon="exclamation-triangle"></v-icon>
            </v-icon-button>
            <v-icon-button type="button" @click="show" v-else :loading="fetchingNotifications">
                <v-icon fa-icon="bell" :badge="unreadNotificationCount"></v-icon>
            </v-icon-button>
        </template>

        <div class="flex flex-row justify-between">
            <div class="flex flex-col ml-10">
                <div>
                    <v-toggle id="only-unread" on-label="Only show unread notifications" off-label="Show all notifications"
                              hint="Whether to show only unread notifications."
                              v-model="showOnlyUnread"></v-toggle>
                </div>
            </div>
            <div class="mr-2">
                <v-button @click="markAllAsRead" :loading="markingAllAsRead">Mark all as read</v-button>
            </div>
        </div>

        <div class="flex flex-col items-center justify-between" v-if="notifications">
            <div v-for="(notification, index) in notifications.data" class="w-full">
                <single-notification @changed="fetchNotifications" :notification="notification">

                </single-notification>
                <hr v-if="index !== (notifications.length - 1)" class="border-gray-200 dark:border-gray-700 ">
            </div>
        </div>

        <v-paginator v-if="notifications" v-model:per-page="perPage" v-model:page="page" :paginator="notifications"></v-paginator>
    </v-modal>
</template>

<script setup lang="ts">

import {onMounted, ref, watch} from 'vue';
import axios from 'axios';
import { route } from 'ziggy-js';
import {Notification} from '../../Types/notifications';
import SingleNotification from './SingleNotification.vue';
import VToggle from '../../Ui/Toggle/VToggle.vue';
import VPaginator from '../../Ui/Paginator/VPaginator.vue';
import {Paginator} from '../../types/Paginator';

const notifications = ref<Paginator<Notification>|null>(null);

const unreadNotificationCount = ref<number>(0);

const props = defineProps<{
    userId: number;
}>();

onMounted(() => {
    // eslint-disable-next-line no-undef
    Echo.private('App.User.' + props.userId)
        .notification((notification: object) => {
            console.log('Notification received: ', notification);
            fetchNotifications();
        });

    fetchNotifications();
});

const showOnlyUnread = ref<boolean>(true);

const fetchingNotifications = ref<boolean>(false);

const page = ref<number>(1);

const perPage = ref<number>(15);

watch([showOnlyUnread, page, perPage], () => {
    fetchNotifications();
});

const failedToFetchNotifications = ref<boolean>(false);

const fetchNotifications = () => {
    fetchingNotifications.value = true;
    failedToFetchNotifications.value = false;
    axios.get(route('api.tools.notifications.notifications.index', {
        only_unread: showOnlyUnread.value,
        page: page.value,
        per_page: perPage.value
    }))
        .then(response => {
            notifications.value = response.data.notifications;
            unreadNotificationCount.value = response.data.unreadCount;
        })
        .catch(error => {
            console.error(error);
            failedToFetchNotifications.value = true;
        })
        .finally(() => {
            fetchingNotifications.value = false;
        });
};


const markingAllAsRead = ref<boolean>(false);
const markAllAsRead = () => {
    markingAllAsRead.value = true;
    axios.post(route('api.tools.notifications.notifications.mark-all-as-read'))
        .then(() => {
            fetchNotifications();
        })
        .catch(error => {
            console.error(error);
            alert('Failed to mark all notifications as read');
        })
        .finally(() => {
            markingAllAsRead.value = false;
        });
};

</script>

<style scoped>

</style>