// app.js
import { createApp } from 'vue';
import SidebarToggle from './Components/Page/SidebarToggle.vue';
import * as Sentry from '@sentry/vue';
import registerTailwindComponents from './registerTailwindComponents';
import Notifications from './Components/Page/Notifications.vue';
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';

const navbarApp = createApp();
navbarApp.component('sidebar-toggle', SidebarToggle);
navbarApp.use(registerTailwindComponents);

navbarApp.mixin(Sentry.createTracingMixins({ trackComponents: true }));
Sentry.attachErrorHandler(navbarApp);

navbarApp.mount('#navbar-vue');



const notificationsApp = createApp();
notificationsApp.component('notification-app', Notifications);
notificationsApp.use(registerTailwindComponents);

window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'reverb',
    key: import.meta.env.VITE_REVERB_APP_KEY,
    wsHost: import.meta.env.VITE_REVERB_HOST,
    // wsHost: import.meta.env.VITE_REVERB_HOST,
    wsPort: import.meta.env.VITE_REVERB_PORT,
    wssPort: import.meta.env.VITE_REVERB_PORT,
    forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
});

notificationsApp.mixin(Sentry.createTracingMixins({ trackComponents: true }));
Sentry.attachErrorHandler(notificationsApp);

notificationsApp.mount('#notifications-vue');
